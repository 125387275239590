
import { Component, Vue } from 'vue-property-decorator'
import { UserDate } from '@/types/userInfo'
import { FileInfo } from '@/types/common'
import { phone, password } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
import UploadFile from '@/components/uploadFile/Index.vue'

type Info = {
  name?: string;
  mobile?: string;
  resource?: FileInfo | null;
  userId?: string;
}
@Component({
  components: { UploadFile }
})
export default class UserInfo extends Vue {
  validatePass = (rule: object, value: string, callback: Function) => {
    if (value !== this.formPassword.newPassword) {
      callback(new Error('两次输入密码不一致!'))
    } else {
      callback()
    }
  }

  private imgUrl = ''
  private imgLoading = false
  private activeName = 'user'
  private info: Info = {
    name: '',
    mobile: '',
    resource: {
      fileName: '',
      filePrefix: '',
      fileUrl: ''
    },
    userId: ''
  }

  private rules = {
    name: [
      {
        required: true,
        message: '请输入姓名',
        trigger: 'blur',
        whitespace: true
      }
    ],
    mobile: [
      {
        required: true,
        message: '请输入手机号码',
        trigger: 'blur'
      },
      { validator: phone, trigger: 'blur' }
    ]
  }

  private formPassword = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    userId: ''
  }

  private rulesPassword = {
    oldPassword: [{ required: true, trigger: ['blur', 'change'] }],
    newPassword: [{ required: true, validator: password, trigger: ['blur', 'change'] }],
    confirmPassword: [{ required: true, validator: this.validatePass, trigger: ['blur', 'change'] }]
  }

  private tableData = {
    loading: false,
    tr: [
      {
        label: '登录系统',
        prop: 'system',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '登录来源',
        prop: 'terminal',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '登录IP',
        prop: 'loginIp',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '登录时间',
        prop: 'ctime',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: [{}]
  }

  private userInfo: UserDate = {
    resource: {
      fileName: '',
      filePrefix: '',
      fileUrl: ''
    }
  }

  private submitShow = false
  private fileType = 'image/jpeg,image/jpg,image/png'
  private total = 0
  private page = 1
  private size = 10

  created () {
    this.imgLoading = true
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo') as any)
    this.imgUrl = this.userInfo.resource ? this.userInfo.resource.filePrefix + this.userInfo.resource.fileUrl : ''
    this.info = {
      name: this.userInfo.name,
      mobile: this.userInfo.mobile,
      resource: this.userInfo.resource,
      userId: this.userInfo.userId
    }
    this.getData()
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.userInfo.userLogPage, {
      userId: this.userInfo.userId,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.tableData.loading = false
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  // 账户信息修改
  submitForm () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.userInfo.updateIparkUser, this.info).then(() => {
          this.$message.success('保存成功')
          this.$store.dispatch('getUserInfo')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  // 头像修改
  submitImgForm (url: string) {
    this.$axios.post(this.$apis.userInfo.updateIparkUser, this.info).then(() => {
      this.$message.success('保存成功')
      this.$store.dispatch('getUserInfo')
      this.imgUrl = url
    })
  }

  // 密码修改
  submitPassword () {
    (this.$refs.formPassword as ElForm).validate((valid) => {
      if (valid) {
        const info = {
          oldPassword: this.formPassword.oldPassword,
          newPassword: this.formPassword.newPassword,
          userId: this.info.userId
        }
        this.$axios.post(this.$apis.userInfo.updatePassword, info).then(() => {
          this.$message.success('保存成功')
        })
      }
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.resource = file
    this.submitImgForm(file.filePrefix + file.fileUrl);
    (this.$refs.uploadFile as any).reset()
  }
}
